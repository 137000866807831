%flex {
  display: flex;
}

%ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

%v-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

%h-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

%full-center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

%lowkey-text {
  color: $grey75;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.125rem;
}

%lowkey-heading {
  @extend %lowkey-text;

  margin: 1.5rem 0 1rem;
  padding: 0;
}

%link-style {
  // TODO: a11y: update colour to meet minimum contrast ratio on white bg
  color: $primary;
  cursor: pointer;
  display: inline-block;
  padding: 0.5rem 0;
  text-decoration: none;
  transition: color $transition-time $transition-ease;
  position: relative;

  svg {
    fill: $primary;
    transition: fill $transition-time $transition-ease;
  }

  &:hover,
  &:focus {
    color: $primary-d10;

    svg {
      fill: $primary-d10;
    }
  }
}

%link-style-button {
  @extend %link-style;
  /* stylelint-disable */
  background: none !important;
  /* stylelint-enable */
  border: 0;
}

%column-buttons-group {
  button {
    /* stylelint-disable */
    margin: 12px 0 !important;
    /* stylelint-enable */
  }
}

%ul-default-style {
  margin: initial;
  padding: initial;

  li {
    list-style-type: disc;
    list-style-position: inside;
  }
}
